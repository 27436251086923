































































import { Component, Vue, Prop } from 'vue-property-decorator'
import modelCollection, { Model } from '@/models/models'
import firebase from '@/plugins/firebase'
@Component
export default class ModelItem extends Vue {
  @Prop({ type: Object, required: true }) item!: Model
  showGroup = true

  close (item: Model, group: string) {
    const removeGroup = firebase.firestore.FieldValue.arrayRemove(group)
    modelCollection.doc(item.id).update({ groups: removeGroup })
  }
}
