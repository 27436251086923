



import { Vue, Component } from 'vue-property-decorator'
import ModelList from '@/components/model/list.vue'

@Component({ components: { ModelList } })
export default class ViewModel extends Vue {
}
