


















































import { Component, Vue } from 'vue-property-decorator'
import DisplayModel from '@/components/common/display-model.vue'
import ModelStore from '@/store/model'
import ModelItem from '@/components/model/item.vue'

@Component<ModelList>({
  components: { DisplayModel, ModelItem }
})
export default class ModelList extends Vue {
  headers = [
    { value: 'id', text: '모델명' },
    { value: 'deviceCount', text: '장치개수' },
    { value: 'updateCount', text: '업데이트중' },
    { value: 'firmwareCount', text: '펌웨어개수' },
    { value: 'viewCount', text: '감시개수' }
  ]

  itemsPerPage = this.breakpointPage
  options = {
    page: 1,
    sortBy: ['deviceCount'],
    sortDesc: [true],
    itemsPerPage: this.itemsPerPage
  }

  expanded = false

  get xs () { return this.$vuetify.breakpoint.xs }

  get breakpoint () {
    return 12 * 4 / this.breakpointPage
  }

  get breakpointPage () {
    let page = 1
    if (this.$vuetify.breakpoint.xs) page = 1 * 4
    else if (this.$vuetify.breakpoint.sm) page = 2 * 4
    else if (this.$vuetify.breakpoint.md) page = 3 * 4
    else if (this.$vuetify.breakpoint.lg) page = 4 * 4
    else if (this.$vuetify.breakpoint.xl) page = 4 * 4
    return page
  }

  get items () {
    return ModelStore.items
  }
}
