
















import { Vue, Component, Prop } from 'vue-property-decorator'
import ModelStore from '@/store/model'

@Component
export default class DisplayModel extends Vue {
  @Prop({ type: String, required: true }) readonly model!: string;

  get to () {
    if (this.model === '전체') return '/device'
    return `/device?modelNo=${this.model}`
  }

  get modelCount () {
    if (this.model === '전체') { return ModelStore.total.deviceCount }

    const f = ModelStore.items.find(item => item.id === this.model)
    if (f) return f.deviceCount
    return 0
  }
}
